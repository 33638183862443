import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link
} from "react-router-dom";
import PrivacyPolicy from './PrivacyPolicy';
import Home from './Home'

const App = () => {
  return <Router>
    <div style={{height: '100vh', width: "100%", backgroundColor: '#333F48', color: 'white', overflow: 'scroll'}}>
      <nav>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 50 }}>
          <Link to='/'>
            <img style={{ height: '5rem' }} src='/home_logo.png' />
          </Link>
          <div>
            <a href='https://github.com/ntatko/homiletics-mobile' style={{paddingRight: 20, textDecoration: 'none'}}>
              <span style={{color: 'white', textDecoration: 'none', fontSize: '1em'}}><strong>Github</strong></span>
            </a>
            <a href="https://blog.homiletics.app" style={{paddingRight: 20, textDecoration: 'none'}}>
              <span style={{color: 'white', textDecoration: 'none', fontSize: '1em'}}><strong>Updates</strong></span>
            </a>
            <Link to="/privacy" style={{textDecoration: 'none'}}>
              <span style={{color: 'white', textDecoration: 'none', fontSize: '1em'}}><strong>Privacy Policy</strong></span>
            </Link>
          </div>
        </div>
      </nav>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/privacy' element={<PrivacyPolicy />} />
      </Routes>
    </div>
    
  </Router>
}

export default App;
