import React from 'react'

const Home = () => {
    return <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexWrap: 'wrap', height: 'calc(100vh - 5rem)', overflow: 'hidden'  }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', color: 'white', alignItems: 'center', padding: 10}}>
            <h1>Homiletics</h1>
            <p>Prepare your homiletics lesson on the go with the homiletics app</p>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexWrap: 'wrap' }}>
                <a href='https://apps.apple.com/us/app/homiletics/id1591978405'>
                    <img alt='App store button (apple)' style={{height: 68}} src='/app_store.svg'/>
                </a>
                <a href='https://play.google.com/store/apps/details?id=com.thirteenone.homiletics&hl=en_US&gl=US'>
                    <img alt='Play store button (android)' style={{height: 100}} src='/google_play.png'/>
                </a>
            </div>
            <div>
                <button onClick={() => window.location.href = 'https://web.homiletics.app'} style={{backgroundColor: '#000000', color: 'white', border: '1px solid white', padding: 12, borderRadius: 10, fontSize: '1.5em', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', textDecoration: 'none'}}>
                    <img alt='Web icon' style={{height: 40, marginRight: 10}} src='/ie.svg'/>
                    <span style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', alignItems: 'start', color: 'white', textDecoration: 'none' }}>
                        <span style={{color: 'white', textDecoration: 'none', fontSize: '0.6em'}}>TRY IT</span>
                        <span style={{color: 'white', textDecoration: 'none'}}>On the web</span>
                    </span>
                </button>
            </div>
        </div>
        <div>
            <img alt='Sample screens in the app' style={{ width: '50em', maxWidth: '100' }} src='/app-screens.png' />
        </div>
    </div>
}


export default Home